import React, { useEffect, useState, useMemo } from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import useComponentsGenerator from "@hooks/useComponentsGenerator";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../../../links";
import CaseStudyItem from "./CaseStudyItem/CaseStudyItem";
import {
  CaseStudiesSectionContainer,
  CaseStudyItemsContainer,
  ButtonContainer,
  StyledMoreCasesButton
} from "./CaseStudiesSection.style";

const PageHeading = loadable(() => import("@common/PageHeading/PageHeading"));

const CaseStudiesSection = ({
  caseStudies,
  indexes,
  heading,
  isHugeMargin,
}) => {
  const [mainCaseStudies, setMainCaseStudies] = useState(null);

  const { isMobile } = useWindowDimensions();
  const [componentElements, generateComponents] = useComponentsGenerator(mainCaseStudies);

  useEffect(() => {
    const caseStudyNodes = caseStudies.nodes;

    const filteredCaseStudies = [];

    caseStudyNodes.forEach(el => {
      const nodeId = parseInt(el.id.split("_")[1], 10);

      const currentIndex = indexes.find(item => item.index === nodeId);

      if (currentIndex) {
        filteredCaseStudies.push({
          element: el,
          order: currentIndex.order,
        });
      }
    });

    setMainCaseStudies(filteredCaseStudies.sort((a, b) => b.order - a.order));
  }, [caseStudies]);

  useMemo(() => {
    if (mainCaseStudies) {
      generateComponents((caseStudy, index) => {
        let editedTitle = null;

        if (isMobile && index === 2) {
          editedTitle = caseStudy.element.title.split(" ").slice(0, 8).join(" ");
        };

        componentElements.push(
          <CaseStudyItem
            key={index + caseStudy.id}
            caseStudy={caseStudy.element}
            editedTitle={editedTitle}
          />
        );
      });
    };
  }, [mainCaseStudies]);

  return (
    <CaseStudiesSectionContainer isHugeMargin={isHugeMargin}>
      <PageHeading heading={heading || "Case Studies"} />
      <CaseStudyItemsContainer>{componentElements}</CaseStudyItemsContainer>
      <ButtonContainer>
          <StyledMoreCasesButton href={GLOBAL_APPLICATION_INTERNAL_LINKS.CASE_STUDIES_PAGE}>
            More cases
          </StyledMoreCasesButton>
      </ButtonContainer>
    </CaseStudiesSectionContainer>
  );
};

export default CaseStudiesSection;
